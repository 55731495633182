@import './fonts/ubuntu/font.css';

html, body, h1, h2, h3, h4, h5, p, button, input {
  font-family: 'Ubuntu', Arial, Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

html, body, div#root {
  height: 100%;
}

div#root {
  display: flex;
  flex-direction: column;
  white-space: pre-line;
}

html {
  font-size: 16px;
  line-height: 24px;
  --font-color-1: #000;
  --font-color-2: #555;
  --font-color-3: #002D5E;
  --link-color: #2C68C2;
  --link-color-2: #0066FF;
  --accent-color-1: #E9ED1A;
  --accent-color-2: #ECF1F8;
  --accent-color-3: #E4FDE5;
  --accent-color-4: #FCFDE4;
  --accent-color-5: #E4F8FD;
  --accent-color-6: #3AB57A;
  --bg-color: #ecf1f8;
  --hover-opacity: 0.8;
  color: var(--font-color-1);
  scroll-behavior: smooth;
  background: white;

  a {
    color: var(--link-color);
  }

  button {
    border: none;
    cursor: pointer;
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background: transparent;
  }
}

h1 {
  font-size: 5.25rem;
  line-height: 5.25rem;
  font-weight: 700;
  margin-bottom: 8px;
}

h2 {
  font-size: 48px;
  line-height: 56px;
  font-weight: 400;
  margin-bottom: 24px;
  color: #0E3E6A;
}

h3 {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
  margin-bottom: 8px;
}

p {
  margin-bottom: 24px;
}

input[type=text], input[type=password] {
  border: 1px solid var(--link-color);
  box-sizing: border-box;
  border-radius: 3px;
  outline: none;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  &:focus {
    box-shadow: 0px 0px 0px 3px var(--link-color);
  }
}

.wrapper {
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 36px;
}

.section-wrapper {
  padding: 0 36px;
}

button[type=submit], button.default {
  border-radius: 0px 0px 0px 20px;
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
  outline: none;

  &:hover {
    opacity: var(--hover-opacity);
  }
}

button.secondary {
  padding: 6px 8px;
  border-radius: 4px;
  &:hover {
    background-color: #0000000a;
  }
}
