.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.spinner {
  position: relative;
  width: 80px;
  height: 80px;

  > div {
    position: absolute;
    border: 4px solid var(--accent-color-6);
    opacity: 1;
    border-radius: 50%;
    animation: spinnerAnimation 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }

    @keyframes spinnerAnimation {
      0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
      }

      100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
      }
    }
  }
}
